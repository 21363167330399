import React, { useEffect, useState } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ResponsiveModal from '../../../rsmCoreComponents/components/ResponsiveModal'
import { Styles } from '../../../types'
import api from '../../../api'
import HtmlViewer from '../../../rsmCoreComponents/components/HtmlViewer/HtmlViewer'

interface TermsAndConditionsDialogProps {
  open: boolean
  isAutomaticPayment: boolean
  onAccept: () => void
  onClose: (hasUserReadTerms: boolean) => void
  hasUserReadTerms: boolean
}

const styles: Styles = {
  dialogContent: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    justifyContent: 'center',
    textAlign: 'center',
    maxHeight: '100%',
    height: '100%',
    padding: '2.5rem 0.5rem 0',
    [theme.breakpoints.only('mobile')]: {
      padding: '1rem 0.5rem 0',
    },
  }),
  title: {
    fontFamily: 'Prelo-Light, sans-serif',
    fontSize: '2.125rem',
    lineHeight: '56px',
    fontWeight: 400,
    backgroundColor: '#3f9c35',
    color: '#fff',
    padding: '0 1rem 1rem',
  },

  termsConditionsButton: (theme) => ({
    width: '15rem',
    justifyContent: 'space-between',
    alignSelf: 'center',
    '& svg': {
      stroke: theme.palette.secondary.main,
      transition: 'stroke 250ms cubic-bezier(0.4, 0, 0.2, 1)',
    },
    '&:hover svg,': {
      stroke: theme.palette.common.white,
    },
    '&:active svg': {
      stroke: theme.palette.text.primary,
    },
    '& .RsmModal-dialogHeadingContainer': {
      '& h1': {
        [theme.breakpoints.up('tablet')]: {
          marginTop: '2rem',
          marginLeft: '3rem',
        },
        [theme.breakpoints.down('desktop')]: {
          fontSize: '2.125rem',
        },
      },
    },
    [theme.breakpoints.down('tablet')]: {
      display: 'flex',
      flexDirection: 'initial',
      flexFlow: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1.5rem',
      paddingTop: '2rem',
    },
  }),
  acceptWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexFlow: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.5rem',
    paddingTop: '2rem',
  },
}

const TermsAndConditionsDialog = ({
  open,
  isAutomaticPayment,
  onAccept,
  onClose,
  hasUserReadTerms,
}: TermsAndConditionsDialogProps) => {
  const { t } = useTranslation()
  const [isRead, setIsRead] = useState(false)
  const [paymentTermsAndConditions, setPaymentTermsAndConditions] = useState('')

  useEffect(() => {
    setIsRead(hasUserReadTerms)
  }, [isAutomaticPayment, hasUserReadTerms])

  useEffect(() => {
    if (!open) {
      setPaymentTermsAndConditions('')
      return () => {}
    }
    const retrievePaymentTermsAndConditions = async () => {
      const fileName = isAutomaticPayment
        ? 'Invoicing/PaymentTermsAndConditionsAutomaticUS.html'
        : 'Invoicing/PaymentTermsAndConditionsSingle.html'
      const urlResponse = await api.user.document_GetDownloadFileUri(
        fileName,
        null,
      )
      const response = await fetch(urlResponse?.data?.downloadUri || '')
      const text = await response.text()
      setPaymentTermsAndConditions(text)
    }

    retrievePaymentTermsAndConditions()
    return () => {}
  }, [open, isAutomaticPayment])

  return (
    <Box className="Rsm-ProfileModal">
      <ResponsiveModal
        open={open}
        onClose={() => onClose(isRead)}
        aria-label={t(
          'Invoicing.PaymentTermsAndConditions.PaymentTermsAndConditions',
        )}
        desktopMaxHeight="34rem"
        desktopMaxWidth="54rem"
        removeHeaderSpacing
        removeCloseButton>
        <Box sx={styles.dialogContent}>
          <Typography variant="h1" sx={styles.title}>
            {t('Invoicing.PaymentTermsAndConditions.TermsAndConditions')}
          </Typography>
          <HtmlViewer
            title={t('PaymentTermsAndConditions.TermsAndConditions')}
            html={paymentTermsAndConditions}
            onScrolledDown={() => setIsRead(true)}
          />
          <Stack>
            {t('Invoicing.PaymentTermsAndConditions.ReadtermsandConditions')}
          </Stack>
          <Stack sx={styles.acceptWrapper}>
            <Button
              variant="outlined"
              onClick={() => onClose(false)}
              sx={{ position: 'sticky' }}>
              {t('Invoicing.PaymentTermsAndConditions.DeclineTermsAndCondPay')}
            </Button>
            <Button
              data-testid="Btn_InvoiceTermsandConditionPage_AcceptAndPay"
              variant="contained"
              onClick={onAccept}
              disabled={!isRead}
              sx={{ position: 'sticky' }}>
              {t('Invoicing.PaymentTermsAndConditions.AcceptTermsAndCondPay')}
            </Button>
          </Stack>
        </Box>
      </ResponsiveModal>
    </Box>
  )
}

export default TermsAndConditionsDialog
