import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { Box, styled, Button, Typography } from '@mui/material'
import {
  InvoicePayerViewModel,
  PaymentInstallmentFrequencyEnum,
  ScheduledInvoicesPaymentViewModel,
} from '@rsmus/ecp-financeservice'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import ScheduledInvoicesDialog from './ScheduledInvoicesDialog'
import InvoicesHeader from './InvoicesHeader'
import { Styles } from '../../../types'
import {
  getPayableAmount,
  getInvoicePayments,
  getPayOnAccountClient,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import {
  setSelectedBankAccount,
  setSelectedCreditCard,
  setSelectedPayer,
  getSelectedBankAccount,
  getSelectedCreditCard,
  getSelectedPaymentMethod,
  setAccountSelectedType,
  SelectedAccountTypeState,
  setNewPaymentMethod,
  getPaymentMethodError,
  setPaymentMethodError,
  getConfirmPaymentError,
  setConfirmPaymentError,
  setDoNotSave,
  sethandleClicked,
} from '../../../store/invoices/paymentInfoSlice'
import api from '../../../api'
import CustomErrorAlert from '../../forms/Alert/CustomErrorAlert/CustomErrorAlert'
import Spinner from '../../forms/Spinner/Spinner'
import PaymentTypePayNow from './PaymentTypePayNow'
import PaymentTypeScheduled from './PaymentTypeScheduled'
import PaymentTypeInstallments from './PaymentTypeInstallments'
import PaymentCategory from './PaymentComponents/PaymentCategory'
import PaymentCategoriesErrosDialog from './PaymentComponents/PaymentCategoriesErrorsDialog'
import PayOnAccount from './PayOnAccount'
import { isCemFeatureEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import { CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT } from '../../../utils/constants/constants'
import { getCemFeatures } from '../../../store/userInfo/userInfoSlice'

const styles: Styles = {
  PaymentTypeContainer: (theme) => ({
    color: theme.palette.text.primary,
    fontFamily: 'Prelo-Book, sans-serif',
    [theme.breakpoints.only('desktop')]: {
      paddingLeft: '6.5rem',
      paddingRight: '6.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  }),
  ErrorSumContainer: (theme) => ({
    [theme.breakpoints.only('desktop')]: {
      paddingLeft: '0.6rem',
      paddingRight: '0.3rem',
      width: '75%',
    },
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '0rem',
      paddingRight: '0rem',
      width: '130%',
      marginLeft: '-1.9rem',
      marginRight: '-1.2rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '0rem',
      paddingRight: '0rem',
      width: '130%',
      marginLeft: '-1.9rem',
      marginRight: '-1.2rem',
    },
  }),
  ConfirmPaymentContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  ButtonContainer: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '6rem',
    width: '100%',
    backgroundColor: 'white',
    marginBottom: '2rem',
    [theme.breakpoints.only('mobile')]: {
      display: 'inline-block',
    },
    [theme.breakpoints.down('desktop')]: {
      marginTop: '1rem',
    },
  }),
  ConfirmPaymentButton: {
    '&.Mui-disabled': {
      color: '#FFFFFF',
    },
  },
  link: (theme) => ({
    color: theme.palette.secondary.main,
    padding: 0,
  }),
  payOnAccountHeaderDescription: (theme) => ({
    fontSize: '1rem',
    lineHeight: '1.5rem',
    marginTop: '-1rem',
    [theme.breakpoints.only('mobile')]: {
      marginTop: '-2rem',
    },
  }),
}

const BodyArea = styled('div')(() => ({
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'row',
  flexBasis: 'auto',
  flexWrap: 'wrap',
}))

const FormArea = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexBasis: 'auto',
  backgroundColor: 'white',
  maxWidth: '65.625rem',
  [theme.breakpoints.down('desktop')]: {
    width: '100%',
  },
}))

const AutopayArea = styled('div')(() => ({
  backgroundColor: 'white',
  width: '33%',
}))

interface PaymentTypeProps {
  isPayOnAccount?: boolean
}

const PaymentType = ({ isPayOnAccount }: PaymentTypeProps) => {
  const methods = useForm({
    mode: 'onSubmit',
    shouldFocusError: false, // This is handled manually below
    reValidateMode: 'onChange',
  })
  const {
    handleSubmit,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
    setFocus,
  } = methods
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isDesktop } = useDeviceType()

  const payableAmount = useSelector(getPayableAmount)
  const invoicePayments = useSelector(getInvoicePayments)
  const selectedBankAccount = useSelector(getSelectedBankAccount)
  const selectedCreditCard = useSelector(getSelectedCreditCard)
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)
  const paymentMethodError = useSelector(getPaymentMethodError)
  const confirmPaymentError = useSelector(getConfirmPaymentError)
  const cemFeatures = useSelector(getCemFeatures)
  const payOnAccountClient = useSelector(getPayOnAccountClient)

  const [formSubmitCount, setFormSubmitCount] = useState(0)
  const confirmButtonRef = useRef<HTMLButtonElement>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isGenericError, setIsGenericError] = useState(false)
  const [scheduledInvoicesData, setScheduledInvoicesData] = useState<
    ScheduledInvoicesPaymentViewModel[]
  >([])
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedPaymentCategory, setSelectedPaymentCategory] =
    useState<string>(
      isPayOnAccount ? t('Invoicing.PayOnAccount') : t('Invoicing.PayNow'),
    )
  const [closedCategories, setClosedCategories] = useState<string[]>([])

  const unavailbleStatus: SelectedAccountTypeState = 'Unavailable'
  const unselectedStatus: SelectedAccountTypeState = 'Unselected'

  const isAccountManager = useMemo(
    () =>
      isCemFeatureEnabled(
        CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT,
        cemFeatures,
        'any',
      ),
    [cemFeatures],
  )

  const handleCloseDialog = () => {
    setClosedCategories([selectedPaymentCategory]) // Add closed category to the list
    setIsDialogOpen(false) // Close the dialog
  }

  const handleFormSubmit = async () => {
    dispatch(sethandleClicked(true))
    handleCloseDialog()

    if (Object.keys(errors).length > 0) {
      setFocus(String('errorsum'))
    }
    setFormSubmitCount((prev) => prev + 1)
  }

  const handlePaymentCategoryChange = (category: string) => {
    setSelectedPaymentCategory(category)
    reset({
      payer: null,
      paymentDate: null,
      numberOfInstallments: 2,
      numberOfInstallmentsOther: '',
      frequency: PaymentInstallmentFrequencyEnum.Monthly,
      paymentMethod: '',
      paymentCategory: category,
    })
    clearErrors([
      'payer',
      'paymentDate',
      'numberOfInstallments',
      'numberOfInstallmentsOther',
      'frequency',
      'paymentMethod',
    ])
    setFormSubmitCount(0)
    dispatch(setSelectedBankAccount(undefined))
    dispatch(setSelectedCreditCard(undefined))
    dispatch(setSelectedPayer(undefined))
  }

  const handlePayerChange = async (
    payer: InvoicePayerViewModel | undefined,
  ) => {
    dispatch(setSelectedBankAccount(undefined))
    dispatch(setSelectedCreditCard(undefined))
    dispatch(setSelectedPayer(payer))
    dispatch(setAccountSelectedType(unselectedStatus))
  }

  const handleSessionChange = (sessionId: string, isError: boolean) => {
    if (isError) {
      setIsGenericError(true)
    }
    setIsLoading(false)
  }

  const resetPaymentMethodStates = () => {
    dispatch(setSelectedBankAccount(undefined))
    dispatch(setSelectedCreditCard(undefined))
    dispatch(setSelectedPayer(undefined))
    dispatch(setAccountSelectedType(unselectedStatus))
    dispatch(setNewPaymentMethod(false))
    dispatch(setDoNotSave(false))
  }

  const handleBackToPreviousPage = () => {
    resetPaymentMethodStates()
    navigate('/invoicing/invoices/pay-invoices')
  }

  const handleCloseConfirmPaymentError = () => {
    setFormSubmitCount(0)
    dispatch(setDoNotSave(false))
    dispatch(setNewPaymentMethod(false))
    dispatch(setConfirmPaymentError(false))
    if (selectedPaymentMethod === 'BankAccount') {
      dispatch(setSelectedBankAccount(undefined))
    } else {
      dispatch(setSelectedCreditCard(undefined))
    }
    dispatch(setAccountSelectedType(unselectedStatus))
    reset(
      {
        paymentMethod: '',
      },
      { keepValues: true },
    )
    confirmButtonRef.current?.focus()
  }

  useEffect(() => {
    // Prevent user from accessing this page directly.
    if (
      (!isPayOnAccount && invoicePayments.length === 0) ||
      (isPayOnAccount && !payOnAccountClient)
    ) {
      navigate('/invoicing')
    }

    dispatch(setAccountSelectedType(unavailbleStatus))

    // unmount useEffect to reset store values when navigating away from this page
    return () => {
      resetPaymentMethodStates()
    }
  }, [])

  // When selected credit card or bank account has been updated programmatically, set the value in the form.
  useEffect(() => {
    setValue(
      'paymentMethod',
      selectedPaymentMethod === 'BankAccount'
        ? selectedBankAccount
        : selectedCreditCard,
    )
  }, [selectedBankAccount, selectedCreditCard])

  const handleButtonClick = () => {
    dispatch(sethandleClicked(true)) // Set state indicating button click
  }

  const invoiceNumbers = useMemo(
    () => invoicePayments.map((payment) => payment.invoiceNumber).join(', '),
    [invoicePayments],
  )

  const loadScheduledInvoicesData = useCallback(async () => {
    if (invoiceNumbers) {
      const { data: ScheduledInvoices } =
        await api.finance.payment_GetScheduledInvoices(invoiceNumbers, 1, true)
      const ScheduledInvoicesDataArray: ScheduledInvoicesPaymentViewModel[] =
        ScheduledInvoices ?? []
      setScheduledInvoicesData(ScheduledInvoicesDataArray)
    }
  }, [invoiceNumbers])

  useEffect(() => {
    if (invoiceNumbers) {
      loadScheduledInvoicesData()
    }
  }, [invoiceNumbers, loadScheduledInvoicesData])

  useEffect(() => {
    const shouldOpenDialog =
      scheduledInvoicesData.length > 0 &&
      !closedCategories.includes(selectedPaymentCategory) &&
      (selectedPaymentCategory === t('Invoicing.SchedulePayment') ||
        selectedPaymentCategory === t('Invoicing.SetupInstallments'))

    setIsDialogOpen(shouldOpenDialog)
  }, [
    scheduledInvoicesData,
    selectedPaymentCategory,
    closedCategories, // Add closedCategories as a dependency
  ])

  useEffect(() => {
    setClosedCategories([]) // Reset closed categories when selected category changes
  }, [selectedPaymentCategory])

  const getPayOnAccountHeader = () => {
    const openAmount = Number(payOnAccountClient?.totalOpenAmount)
    const headerDescription = (
      <Typography sx={styles.payOnAccountHeaderDescription}>
        {t('Invoicing.PayOnAccountPage.HeaderDescription')}{' '}
        <Button
          data-testid="Btn_PayOnAccount_ReturnLink"
          component="span"
          disableFocusRipple
          disableRipple
          role="link"
          sx={styles.link}
          onClick={() => {
            resetPaymentMethodStates()
            navigate('/invoicing/invoices')
          }}>
          <Box component="span" sx={{ textDecoration: 'underline' }}>
            {t('Invoicing.PayOnAccountPage.OpenInvoices')}
          </Box>
        </Button>
        .
      </Typography>
    )
    return (
      <InvoicesHeader
        primaryHeaderId="PayOnAccount"
        primaryHeaderText={t('Invoicing.PayOnAccount')}
        primaryHeaderDescription={headerDescription}
        backButtonId={
          isAccountManager
            ? 'BackToAccountManagementPage'
            : 'BackToDashboardPage'
        }
        backButtonText={
          isAccountManager
            ? t('Invoicing.PayOnAccountPage.BackToAccountManagement')
            : t('Invoicing.PayOnAccountPage.BackToDashboard')
        }
        handleNavigation={() => {
          resetPaymentMethodStates()
          if (isAccountManager) {
            navigate(
              `/invoicing/account-management/${payOnAccountClient?.clientId}`,
            )
          } else {
            navigate(`/invoicing/dashboard/${payOnAccountClient?.clientId}`)
          }
        }}
        amountHeaderId="OpenAmount"
        amountHeaderText={t('Invoicing.OpenAmount')}
        amount={openAmount}
      />
    )
  }

  return (
    <>
      <Spinner open={isLoading} />
      <CustomErrorAlert
        testId="Banner_PaymentType_PaymentUnableToProcess"
        open={confirmPaymentError}
        close={handleCloseConfirmPaymentError}
        disableAutoHide
        header="Alert.PaymentFailed"
        message="Alert.PaymentUnableToProcess"
      />
      <CustomErrorAlert
        testId="Banner_PaymentType_GenericErrorMessage"
        header="Alert.GenericErrorHeader"
        open={isGenericError}
        close={() => setIsGenericError(false)}
        disableAutoHide
        message="Alert.GenericErrorMessage"
      />
      <CustomErrorAlert
        testId="Banner_PaymentType_PaymentMethodError"
        open={paymentMethodError}
        close={() => dispatch(setPaymentMethodError(false))}
        disableAutoHide
        header="Alert.PaymentMethodErrorHeader"
        message="Alert.PaymentMethodError"
      />
      {isPayOnAccount ? (
        getPayOnAccountHeader()
      ) : (
        <InvoicesHeader
          primaryHeaderId="PaymentType"
          primaryHeaderText={t('Invoicing.Payment')}
          primaryHeaderDescription={t('Invoicing.PaymentSelectionWillApply')}
          backButtonId="BackToPreviousPage"
          backButtonText={t('Invoicing.BackToPreviousPage')}
          handleNavigation={handleBackToPreviousPage}
          amountHeaderId="TotalPayableAmount"
          amountHeaderText={t('Invoicing.TotalPayableAmount')}
          amount={payableAmount}
        />
      )}
      <BodyArea sx={styles.PaymentTypeContainer}>
        <form
          style={{ width: '100%' }}
          onSubmit={handleSubmit(() => handleFormSubmit())}>
          <FormArea sx={styles.FormArea}>
            <Box sx={styles.ErrorSumContainer}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormProvider {...methods}>
                <PaymentCategoriesErrosDialog
                  selectedPaymentCategory={selectedPaymentCategory}
                />
              </FormProvider>
            </Box>
          </FormArea>
          <FormArea>
            <Box style={{ padding: '0 ', width: '100%' }}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormProvider {...methods}>
                {isPayOnAccount ? (
                  <PayOnAccount
                    formSubmitCount={formSubmitCount}
                    sessionCreating={() => setIsLoading(true)}
                    sessionChange={handleSessionChange}
                    payerChange={handlePayerChange}
                  />
                ) : (
                  <>
                    <PaymentCategory
                      paymentCategoryChanged={handlePaymentCategoryChange}
                    />

                    {selectedPaymentCategory === t('Invoicing.PayNow') && (
                      <PaymentTypePayNow
                        formSubmitCount={formSubmitCount}
                        sessionCreating={() => setIsLoading(true)}
                        sessionChange={handleSessionChange}
                        payerChange={handlePayerChange}
                      />
                    )}

                    {selectedPaymentCategory ===
                      t('Invoicing.SchedulePayment') && (
                      <PaymentTypeScheduled
                        formSubmitCount={formSubmitCount}
                        sessionCreating={() => setIsLoading(true)}
                        sessionChange={handleSessionChange}
                        payerChange={handlePayerChange}
                      />
                    )}

                    {selectedPaymentCategory ===
                      t('Invoicing.SetupInstallments') && (
                      <PaymentTypeInstallments
                        formSubmitCount={formSubmitCount}
                        sessionCreating={() => setIsLoading(true)}
                        sessionChange={handleSessionChange}
                        payerChange={handlePayerChange}
                      />
                    )}
                  </>
                )}
              </FormProvider>
            </Box>
          </FormArea>
          {isDesktop && (
            <AutopayArea>
              <span /> {/* Autopay box will go here */}
            </AutopayArea>
          )}
          <Box sx={styles.ButtonContainer}>
            <Box sx={styles.ConfirmPaymentContainer}>
              <Button
                type="submit"
                disableFocusRipple
                ref={confirmButtonRef}
                sx={styles.ConfirmPaymentButton}
                variant="contained"
                disabled={
                  scheduledInvoicesData.length > 0 &&
                  (selectedPaymentCategory === t('Invoicing.SchedulePayment') ||
                    selectedPaymentCategory ===
                      t('Invoicing.SetupInstallments'))
                }
                onClick={handleButtonClick}>
                {t('Invoicing.ConfirmPayment')}
              </Button>
            </Box>
          </Box>
          {isPayOnAccount && (
            <Box sx={{ paddingBottom: '2rem', textAlign: 'center' }}>
              {t('Invoicing.PayOnAccountPage.AssistanceMessage')}
            </Box>
          )}
          <ScheduledInvoicesDialog
            Dialogtext={t('Invoicing.ScheduledinvoicesDialog.DialogText')}
            Subtext1={t('Invoicing.ScheduledinvoicesDialog.SubText1')}
            Subtext2={t('Invoicing.ScheduledinvoicesDialog.SubText2')}
            buttonCloseText={t('Invoicing.ScheduledinvoicesDialog.Close')}
            open={isDialogOpen}
            onClose={handleCloseDialog}
            scheduledInvoicesData={scheduledInvoicesData}
          />
        </form>
      </BodyArea>
    </>
  )
}

PaymentType.defaultProps = {
  isPayOnAccount: false,
}

export default PaymentType
