import React, { useCallback, useEffect, useState } from 'react'
import { Box, Theme, Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import ResponsiveModal from '../../../rsmCoreComponents/components/ResponsiveModal'
import { PaymentMethodType, Styles } from '../../../types'
import {
  FINANCESERVICE_BASE_URL,
  PAYMENT_GATEWAY_IFRAME_URL,
  PAYMENT_TIMEOUT_DELAY,
} from '../../../envVariables'
import {
  SelectedAccountTypeState,
  getSelectedPayer,
  setAccountSelectedType,
  setNewPaymentMethod,
  setSelectedBankAccount,
  setSelectedCreditCard,
  setPaymentMethodError,
} from '../../../store/invoices/paymentInfoSlice'
import CountdownTimer from '../../CountdownTimer'
import TimeIcon from '../../icons/TimeIcon'
import { CustomErrorAlert } from '../../forms/Alert'
import { getUserInfo } from '../../../store/userInfo/userInfoSlice'
import { appInsights } from '../../../rsmCoreComponents/hooks/appInsights'

const styles: Styles = {
  container: (theme) => ({
    position: 'relative',
    marginBottom: '2rem',
    padding: 0,
    width: '100%',
    height: '100%',
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '5.5rem',
      paddingRight: '5.5rem',
    },
    [theme.breakpoints.only('desktop')]: {
      paddingLeft: '6.5rem',
      paddingRight: '6.5rem',
    },
  }),
  headerContainer: () => ({
    fontFamily: 'Prelo-Book, sans-serif',
    paddingTop: '0.5rem',
  }),
  header: (theme) => ({
    fontSize: '3rem',
    lineHeight: '1.5rem',
    fontWeight: 400,
    [theme.breakpoints.only('mobile')]: {
      lineHeight: '3.125rem',
      marginLeft: '0.8rem',
      maxWidth: '19.375rem',
    },
  }),
  subHeader: (theme) => ({
    fontSize: '2.25rem',
    lineHeight: '1.5rem',
    fontWeight: 400,
    marginTop: '2rem',
    [theme.breakpoints.only('mobile')]: {
      fontSize: '1.75rem',
      marginTop: '1rem',
      marginLeft: '0.8rem',
      marginRight: '0.5rem',
      maxWidth: '19.375rem',
    },
  }),
  description: (theme) => ({
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    fontWeight: 300,
    marginTop: '1rem',
    marginBottom: '1rem',
    [theme.breakpoints.only('mobile')]: {
      fontSize: '1rem',
      marginLeft: '0.8rem',
      marginRight: '0.5rem',
      maxWidth: '19.375rem',
    },
  }),
  timeoutContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: '2rem',
    marginTop: '2rem',
  },
  iconContainer: {
    display: 'block',
    margin: 'auto',
  },
  countDownStyle: {
    fontFamily: 'Prelo-Book, sans-serif',
    lineHeight: '2.5rem',
    fontSize: '2.25rem',
    fontWeight: 300,
  },
  timeoutText: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1.5rem',
    fontWeight: 400,
  },
}

interface TempusModalProps {
  closeHandler: () => void
  refreshPaymentData: (paymentMethod: PaymentMethodType) => void
  iframeSrc: string
  paymentMethod: PaymentMethodType
  open: boolean
  trackNewPaymentMethod?: boolean
}

type CallbackResponse = {
  type: string
  responseCode: number
  success: boolean
  paymentMethodId: number
}

type IframePost = {
  type: string
  message: string
}

type Error = {
  message: string
  stack?: string
  source?: string
  lineno?: string
  colno?: string
  url?: string
  statusCode?: string
  status?: string
  textStatus?: string
}

type IframeError = {
  type: string
  source: string
  error: Error
}

const PaymentGatewayModal = ({
  open,
  closeHandler,
  refreshPaymentData,
  iframeSrc,
  paymentMethod,
  trackNewPaymentMethod,
}: TempusModalProps) => {
  const dispatch = useDispatch()
  const selectedPayer = useSelector(getSelectedPayer)
  const [iframeHeight, setIframeHeight] = useState('100%')
  const [screenReaderMessage, setScreenReaderMessage] = useState('')
  const [showTimer, setShowTimer] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const { t } = useTranslation()
  const userInfo = useSelector(getUserInfo)

  function closeError() {
    setShowError(false)
  }
  const handleCallbackResponse = (
    ev: CallbackResponse,
    payerPaymentMethod: PaymentMethodType,
  ) => {
    if (!ev.responseCode) return
    if (ev.success) {
      const newStatus: SelectedAccountTypeState = 'New'
      // Only track the new payment method if the user is doing a workflow where they will immediately use the new payment method.
      if (trackNewPaymentMethod) {
        dispatch(setNewPaymentMethod(true))
      }
      dispatch(setAccountSelectedType(newStatus))

      if (paymentMethod === 'BankAccount') {
        dispatch(setSelectedBankAccount(ev.paymentMethodId))
      } else {
        dispatch(setSelectedCreditCard(ev.paymentMethodId))
      }
      refreshPaymentData(payerPaymentMethod)
    } else {
      dispatch(setPaymentMethodError(true))
    }
    closeError()
    closeHandler()
  }

  const handleIframeResponse = (ev: IframePost) => {
    if (ev.message === 'cancel') {
      closeError()
      closeHandler()
    } else if (ev.type === 'client-error') {
      setShowError(true)
    }
  }

  const handleResize = (ev: IframePost) => {
    if (ev.message) {
      setIframeHeight(ev.message)
    }
  }

  const handleLogToAppInsights = (ev: IframeError) => {
    appInsights.trackException({
      exception: new Error(ev.error.message),
      severityLevel: SeverityLevel.Error,
      properties: {
        origin: 'TempusIframe',
        source: `${ev.source}.${ev.error.source}`,
        lineno: ev.error.lineno,
        colno: ev.error.colno,
        stack: ev.error.stack,
        url: ev.error.url,
        statusCode: ev.error.statusCode,
        status: ev.error.status,
        textStatus: ev.error.textStatus,
        type: ev.type,
      },
    })

    if (ev.type === 'server-error' || ev.type === 'network-error') {
      setShowError(true)
    }
  }

  const renderErrorToast = () => (
    <Box>
      <CustomErrorAlert
        testId="Banner_PaymentGatewayModal_Error"
        message={t('Invoicing.IframeGenericErrorMessage')}
        header={t('Invoicing.IframeGenericErrorMessageHeader')}
        open
        disableAutoHide
        // eslint-disable-next-line react/jsx-no-bind
        close={closeError}
        displayInModal
      />
    </Box>
  )

  const getSubHeaderText = () => {
    if (paymentMethod === 'BankAccount') {
      return t('Invoicing.PaymentGateway.AddNewBankAccount')
    }

    return t('Invoicing.PaymentGateway.AddNewCreditCardAccount')
  }

  useEffect(() => {
    setShowTimer(false)
  }, [open])

  useEffect(() => {
    const handlePostMessage = (
      ev: MessageEvent<CallbackResponse | IframePost | IframeError>,
    ) => {
      const iframeUrl = PAYMENT_GATEWAY_IFRAME_URL as string

      if (
        ev.origin.toLowerCase() === FINANCESERVICE_BASE_URL.toLowerCase() ||
        ev.origin.toLowerCase() ===
          iframeUrl.substring(0, iframeUrl.length - 3).toLowerCase()
      ) {
        if (typeof ev.data !== 'object') return
        if (ev.data.type === 'callback')
          handleCallbackResponse(ev.data as CallbackResponse, paymentMethod)
        if (ev.data.type === 'button-click' || ev.data.type === 'client-error')
          handleIframeResponse(ev.data as IframePost)
        if (
          ev.data.type === 'client-error' ||
          ev.data.type === 'server-error' ||
          ev.data.type === 'network-error'
        )
          handleLogToAppInsights(ev.data as IframeError)
        if (ev.data.type === 'resize') handleResize(ev.data as IframePost)
      }
    }

    window.addEventListener('message', handlePostMessage)
    return () => window.removeEventListener('message', handlePostMessage)
  }, [selectedPayer, paymentMethod])

  const computedStyles = {
    iframeContainer: useCallback(
      (theme: Theme) => ({
        position: 'relative',
        height: iframeHeight === '100%' ? 'calc(100% - 10.5rem)' : iframeHeight,
        minHeight: '65rem',
        inset: 0,
        [theme.breakpoints.only('mobile')]: {
          maxWidth: '19.375rem',
          minHeight:
            userInfo.isEmployee && paymentMethod === 'CreditCard'
              ? '25rem'
              : '65rem',
        },
        [theme.breakpoints.only('tablet')]: {
          minHeight: paymentMethod === 'CreditCard' ? '22.5rem' : '40rem',
        },
        [theme.breakpoints.only('desktop')]: {
          minHeight: paymentMethod === 'CreditCard' ? '25rem' : '40rem',
        },
      }),
      [iframeHeight],
    ),
  }

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (open) {
      const delayBeforeTimeoutIsShown = PAYMENT_TIMEOUT_DELAY
      timer = setTimeout(() => setShowTimer(true), delayBeforeTimeoutIsShown)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
      setShowError(false)
    }
  }, [open])

  return (
    <Box className="Rsm-TempusModal">
      <ResponsiveModal
        onClose={() => {
          closeError()
          closeHandler()
        }}
        removeContentSpacing
        desktopMaxHeight="55.375rem"
        showTimerSlot={
          showTimer && (
            <Box sx={styles.timeoutContainer}>
              <Box aria-hidden sx={styles.iconContainer}>
                <TimeIcon height="5.625rem" width="5.625rem" />
              </Box>
              <Box className="RsmModal-dialog" sx={{ marginTop: '0.625rem' }}>
                <Box sx={styles.countDownStyle}>
                  <CountdownTimer
                    onlyShowSeconds
                    minutesRemaining={1}
                    onComplete={() => {
                      closeError()
                      closeHandler()
                    }}
                    on59secWarning={() =>
                      setScreenReaderMessage(t('Invoicing.Exp59Sec'))
                    }
                    on30secWarning={() =>
                      setScreenReaderMessage(t('Invoicing.Exp30Sec'))
                    }
                    on10secWarning={() =>
                      setScreenReaderMessage(t('Invoicing.Exp10Sec'))
                    }
                    on5secWarning={() =>
                      setScreenReaderMessage(t('Invoicing.Exp5Sec'))
                    }
                  />
                  <Box
                    className="sr-only"
                    aria-atomic="true"
                    aria-live="assertive">
                    {screenReaderMessage}
                  </Box>
                </Box>
                <Box sx={styles.timeoutText}>
                  {t('Invoicing.ExpYourSessionIsAboutToExpire')}
                </Box>
              </Box>
            </Box>
          )
        }
        open={open}>
        <Box sx={styles.container}>
          <Box sx={styles.headerContainer}>
            {showError ? renderErrorToast() : null}
            <Box
              component="h1"
              sx={styles.header}
              id="RsmModal-title"
              data-testid="Hed_Invoicing_header">
              {t('Invoicing.PaymentGateway.PaymentInformation')}
            </Box>
            <Box component="h2" sx={styles.subHeader}>
              {getSubHeaderText()}
            </Box>
            <Typography sx={styles.description}>
              {t('Invoicing.PaymentGateway.TimeoutMessage')}
            </Typography>
          </Box>
          <Box sx={(theme) => computedStyles.iframeContainer(theme)}>
            <iframe
              id="tempusIframe"
              src={iframeSrc}
              title={t('Invoicing.IframeTitle')}
              width="100%"
              height={iframeHeight}
            />
          </Box>
        </Box>
      </ResponsiveModal>
    </Box>
  )
}

PaymentGatewayModal.defaultProps = {
  trackNewPaymentMethod: false,
}

export default PaymentGatewayModal
